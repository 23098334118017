import { IAddDocsToTestForm } from 'src/page/systematic-completion/tests/test-documentation/AddDocumentsToTestModal'
import { IPaginateResult } from 'src/service/OrgTypes'
import { ImportTask } from '../context/ImportContext/ImportTypes'
import {
  IDocumentHistory,
  IFileContainer,
  IFileContainerSubmit,
  IFileVersion,
  IProjectFile,
} from '../document/types/IFileContainer'
import { IStepsMetadataOnly } from '../document/types/IFlow'
import { request } from './service-utils'

export const createDocument = (
  projectId: number,
  folderId: number,
  document: Partial<IFileContainerSubmit>,
): Promise<IFileContainer> => {
  return request(
    `projects/${projectId}/folders/${folderId}/file_containers`,
    'POST',
    document,
  )
}

export const generateDocumentNumber = (
  projectId: number,
  folderId: number,
  document: Partial<IFileContainerSubmit>,
  validate?: boolean,
): Promise<{ file_container: IFileContainer; errors?: string }> => {
  return request(
    `projects/${projectId}/folders/${folderId}/generate_record_id`,
    'POST',
    {
      ...document,
      validate_record_id: validate,
    },
  )
}

export const editDocument = (
  projectId: number,
  documentId: number,
  document: Partial<IFileContainerSubmit>,
  options?: { editRecordId?: boolean },
): Promise<IFileContainer> => {
  const { editRecordId = true } = options || {}
  return request(
    `projects/${projectId}/file_containers/${documentId}?reset_record_id=${editRecordId}`,
    'PUT',
    document,
  )
}

export const reviseDocument = (
  projectId: number,
  documentId: number,
  comment?: string,
): Promise<IFileContainer> => {
  return request(
    `projects/${projectId}/file_containers/${documentId}/revise`,
    'PUT',
    { comment },
  )
}

export const massUpdateDocument = (
  projectId: number,
  folderId: number,
  documentIds: number[],
  document: Partial<IFileContainerSubmit>,
  editRecordId = true,
): Promise<IFileContainer> => {
  return request(
    `projects/${projectId}/folders/${folderId}/file_containers_mass_update?reset_record_id=${editRecordId}`,
    'PUT',
    {
      ...document,
      file_container_ids: documentIds,
    },
  )
}

export const validateDocumentNumber = (
  projectId: number,
  documentId: number,
  recordId: string,
): Promise<{ file_container: IFileContainer; errors?: string }> => {
  return request(
    `projects/${projectId}/file_containers/${documentId}/validate_record_id`,
    'POST',
    { record_id: recordId },
  )
}

export const getSystemDocuments = (
  projectId: number,
  systemId: number,
): Promise<IFileContainer[]> => {
  return request(
    `projects/${projectId}/systems/${systemId}/file_containers`,
    'GET',
  )
}

export const getDocument = (
  projectId: number,
  documentId: number,
): Promise<IFileContainer> => {
  return request(`projects/${projectId}/file_containers/${documentId}`, 'GET')
}

export const getDocumentHistory = (
  projectId: number,
  documentId: number,
): Promise<{
  review_access: boolean
  admin_access: boolean
  history: IDocumentHistory[]
}> => {
  return request(
    `projects/${projectId}/file_containers/${documentId}/history`,
    'GET',
  )
}

export const getDocumentStepMetaData = (
  projectId: number,
  documentId: number,
): Promise<IStepsMetadataOnly[]> => {
  return request(
    `projects/${projectId}/file_containers/${documentId}/steps_metadata`,
    'GET',
  )
}

export const deleteMultipleDocument = (projectId: number, data: number[]) => {
  return request(
    `projects/${projectId}/remove_multiple_file_containers`,
    'POST',
    { ids: data },
  )
}

export const deleteDocument = (projectId: number, documentId: number) => {
  return request(
    `projects/${projectId}/file_containers/${documentId}`,
    'DELETE',
  )
}

export const fileContainerValidateFromJson = (
  projectId: number,
  folderId: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/folders/${folderId}/file_containers/validate_for_import_job`,
    'POST',
    data,
  )
}

export const updateFileContainerSystems = (
  projectId: number,
  fileContainerId: number,
  systemsIds: number[],
  removedSystemsIds: number[],
) => {
  return request(
    `projects/${projectId}/file_containers/${fileContainerId}/add_systems`,
    'POST',
    {
      system_ids: systemsIds,
      removed_systems_ids: removedSystemsIds,
    },
  )
}

export const addFileContainerDeliveries = (
  projectId: number,
  fileContainerId: number,
  deliveryIds: number[],
) => {
  return request(
    `projects/${projectId}/file_containers/${fileContainerId}/add_deliveries`,
    'POST',
    {
      delivery_ids: deliveryIds,
    },
  )
}

export const removeFileContainerDeliveries = (
  projectId: number,
  fileContainerId: number,
  deliveryIds: number[],
) => {
  return request(
    `projects/${projectId}/file_containers/${fileContainerId}/remove_deliveries`,
    'POST',
    {
      delivery_ids: deliveryIds,
    },
  )
}

export const addCommentToStep = (
  projectId: number,
  documentId: IFileContainer['id'],
  stepId: number,
  comment: string,
): Promise<IFileContainer> => {
  return request(
    `projects/${projectId}/file_containers/${documentId}/steps/${stepId}/comments`,
    'POST',
    { content: comment },
  )
}

export const getFileContainersWithPagination = (
  projectId: number,
  page: number,
  perPage: number,
  activeFilters?: any,
): Promise<IPaginateResult<IFileContainer>> => {
  return request(
    `projects/${projectId}/file_containers/pages/${page}/${perPage}`,
    'POST',
    activeFilters,
  )
}

export const getCompactFileContainersWithPagination = (
  projectId: number,
  page: number,
  perPage: number,
  activeFilters?: any,
): Promise<IPaginateResult<IFileContainer>> => {
  return request(
    `projects/${projectId}/file_containers/compact/pages/${page}/${perPage}`,
    'POST',
    activeFilters,
  )
}

export const getFileContainersByIds = (
  projectId: number,
  ids: number[],
): Promise<IFileContainer[]> => {
  return request(`projects/${projectId}/file_containers/ids`, 'POST', { ids })
}

export const getStep = async (
  projectId: number,
  docId: number,
  stepId: number,
) => {
  const res = await request(
    `projects/${projectId}/file_containers/${docId}/steps/${stepId}`,
    'GET',
  )
  return res.next_steps
}

export const getDocProjectFile = (
  projectId: number,
  docId: string,
  fileId: string,
): Promise<IProjectFile> => {
  return request(
    `projects/${projectId}/file_containers/${docId}/project_files/${fileId}`,
    'GET',
  )
}

export const assignFile = (
  projectId: number,
  docId: number,
  fileId: number,
) => {
  return request(
    `projects/${projectId}/file_containers/${docId}/project_files/${fileId}`,
    'PUT',
  )
}

export const updateProjectFile = (
  projectId: number,
  docId: number,
  fileId: number,
  body: Partial<IProjectFile>,
) => {
  return request(
    `projects/${projectId}/file_containers/${docId}/project_files/${fileId}`,
    'PUT',
    body,
  )
}

export const uploadUsingLink = (
  projectId: number,
  docId: number,
  file_url: string,
  _file_name?: string,
) => {
  return request(
    `projects/${projectId}/file_containers/${docId}/project_files`,
    'POST',
    { file_url },
  )
}

export const uploadUsingFileVersion = (
  projectId: number,
  docId: number,
  fileVersion: IFileVersion,
) => {
  const { file_url, name, content_type, file_size } = fileVersion
  return request(
    `projects/${projectId}/file_containers/${docId}/project_files`,
    'POST',
    { file_url, name, content_type, file_size },
  )
}

export const uploadMultipleUsingFileVersion = (
  projectId: number,
  file_container_ids: number[],
  fileVersion: IFileVersion,
) => {
  const { file_url, name, content_type, file_size } = fileVersion
  return request(
    `projects/${projectId}/file_containers/project_files/multiple`,
    'POST',
    { file_container_ids, file_url, name, content_type, file_size },
  )
}

export const downloadMultiple = (
  projectId: number,
  filecontainerIds: IFileContainer['id'][],
  lastRevisionOnly: boolean,
): Promise<ImportTask> => {
  return request(
    `projects/${projectId}/file_containers/download_files`,
    'POST',
    {
      file_containers: filecontainerIds,
      last_revision_only: lastRevisionOnly,
    },
  )
}

export const deleteFile = (
  projectId: number,
  docId: number,
  filedId: number,
) => {
  return request(
    `projects/${projectId}/file_containers/${docId}/project_files/${filedId}`,
    'DELETE',
  )
}

export const getProjectDocuments = (
  projectId: number,
): Promise<IFileContainer[]> => {
  return request(`projects/${projectId}/file_containers`, 'GET')
}

export const addTestDocumentation = (
  projectId: number,
  body: IAddDocsToTestForm,
): Promise<string> => {
  return request(
    `/projects/${projectId}/file_containers/add_test_documentation`,
    'POST',
    body,
  )
}

export const addFileContainersToSubcontractor = (
  projectId: number,
  fileContainerIds: number[],
  subcontractorId: string,
): Promise<string> => {
  return request(
    `/projects/${projectId}/file_containers/subcontractor/${subcontractorId}/add_file_containers`,
    'POST',
    { file_container_ids: fileContainerIds },
  )
}

export const removeFileContainersFromSubcontractor = (
  projectId: number,
  fileContainerIds: number[],
  subcontractorId: string,
): Promise<string> => {
  return request(
    `/projects/${projectId}/file_containers/subcontractor/${subcontractorId}/remove_file_containers`,
    'POST',
    { file_container_ids: fileContainerIds },
  )
}
